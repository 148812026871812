import React from 'react';
import './style.css'; 
import { useToast } from '../ToastProvider';

const ToastDisplay = () => {
  const { toast } = useToast();

  if (!toast) return null; 

  return (
    <div className={`toast ${toast.type}`}>
      {toast.message}
    </div>
  );
};

export default ToastDisplay;
