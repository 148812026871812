export const JobData = [
    {
      id: 1,
      title: "Financial Analyst",      
      location:"Location: Ahmedabad, India",
      describe:"We are looking for a driven and detail-oriented Financial Analyst to join our dynamic team. If you have up to 3 years of experience in Stock Markets and investments, and you are keen on using your skills to build financial models, this role is perfect for you. You will play a key part in driving data-driven insights and developing robust financial models that support OneCase’s product offerings and market strategy",
      requirements:"Requirements & Qualifications",
      point1:"0-3 years of experience in financial markets, investments, or a related field.",
      point2:"CA or CFA (Level 2 or above).",
      point3:"Proficiency in financial analysis and modeling tools (Excel/ Python/ R etc.",
      point4:"Strong analytical and quantitative skills.",
      point5:"Good understanding of stock markets, trading mechanisms, and investment products.",
      point6:"Excellent communication and presentation skills.",
      point7:"Ability to work collaboratively in a fast-paced, tech-driven environment.",
      responsibilities:"Key Responsibilities",
      key1:"Conduct in-depth analysis of stock markets, investment trends, and financial data to support decision making.",
      key2:"Develop and maintain financial models, including valuation models, to forecast market trends and business performance.", 
      key3:"Evaluate existing business processes and identify areas for improvement or optimization.", 
      key4:"Translate raw financial data into actionable insights, identifying key patterns and opportunities.", 
      key5:"Work closely with product and technology teams to ensure accurate implementation of financial models within the platform.", 
      key6: "Stay updated on market developments, economic changes, and investment opportunities to provide timely recommendations.", 
      key7:"Prepare financial reports and presentations for internal stakeholders and customers."
    },
    // {
    //   id: 2,
    //   title: "PHP Developer",
    //   location:"Location: Ahmedabad, India",
    //   describe:"We are seeking a skilled Business Analyst with a strong background in the Finance and Investment domain to join our dynamic team. The ideal candidate will be responsible for doing research of publicly available data, analyzing complex financial data, identifying trends, and providing actionable insights to support strategic decision-making across the organization. Candidate will also be responsible for development of Product.",
    // },
    // {
    //   id: 3,
    //   title: ".Net Developer",
    //   location:"Location: Ahmedabad, India",
    //   describe:"We are seeking a skilled Business Analyst with a strong background in the Finance and Investment domain to join our dynamic team. The ideal candidate will be responsible for doing research of publicly available data, analyzing complex financial data, identifying trends, and providing actionable insights to support strategic decision-making across the organization. Candidate will also be responsible for development of Product.",
    // },
    // {
    //   id: 4,
    //   title: "Android Developer",
    //   location:"Location: Ahmedabad, India",
    //   describe:"We are seeking a skilled Business Analyst with a strong background in the Finance and Investment domain to join our dynamic team. The ideal candidate will be responsible for doing research of publicly available data, analyzing complex financial data, identifying trends, and providing actionable insights to support strategic decision-making across the organization. Candidate will also be responsible for development of Product.",
    // }
  ];