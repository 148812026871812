import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import IconHome from '../Icons/IconHome'
import "./style.css"
import IconClose from '../Icons/IconClose';

export default function Navigation({ isDarkMode, onMenuClick }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth <= 767;
      setIsMobile(isMobileView);
      if (isMobileView) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    // If the window width is less than 767px, close the menu on link click
    if (isMobile) {
      setIsMenuOpen(false);
    }
  };

  return (
    <nav className={`${isDarkMode ? 'darknav' : 'lightnav'}`}>
      {isMobile && (
        <div className='PhoneMenuOpen' onClick={toggleMenu}>
          {isMenuOpen ? <IconClose /> : <IconHome />}
        </div>
      )}
      <ul className={`menu ${isMenuOpen ? 'open' : ''}`}>
     
     
        <li className='desktoponly'>
          <NavLink
            to="/"
            onClick={() => {
              handleLinkClick();
              onMenuClick();
            }}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <IconHome />
          </NavLink>
        </li>
       
      </ul>
    </nav>
  );
}
