import React, { useState } from "react";
import IconLinkdin from "../../component/Icons/IconLinkdin";
import IconEmail from "../../component/Icons/IconEmail";
import './style.css'
import { Link } from "react-router-dom";
import axios from "axios";
import { useToast } from "../services/ToastProvider";

export default function ContactUs() {
  // State to hold form input values
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Subject: '',
    Message: ''
  });
  
  // State to manage form submission status
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const backurl = process.env.REACT_APP_API_KEY;
  const { showToast } = useToast();

  // Handle input changes and update state
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value, // Directly map the name to the state key
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    // Validation
    if (!formData.Name || formData.Name.trim().length < 3) {
      showToast("Name must be at least 3 characters long.", 'info');
      setIsSubmitting(false);
      return;
    }
  
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!formData.Email || !emailRegex.test(formData.Email)) {
      showToast("Please provide a valid email address.", 'info');
      setIsSubmitting(false);
      return;
    }
  
    if (!formData.Subject) {
      showToast("Subject is required.", 'info');
      setIsSubmitting(false);
      return;
    }
  
    if (!formData.Message) {
      showToast("Message is required.", 'info');
      setIsSubmitting(false);
      return;
    }
  
    try {
      // Sending the form data to the server using axios
      const response = await axios.post(`${backurl}/contact-from`, formData, {
        headers: {
          "Content-Type": "application/json"
        }
      });
  
      console.log("response", response);
  
      // Check if the response was successful
      if (response.data.success) {
        showToast("Form submitted successfully!", 'success');
  
        // Clear the form data by resetting the state
        setFormData({
          Name: '',
          Email: '',
          Subject: '',
          Message: ''
        });
      } else {
        showToast(response.data.message || "Something went wrong. Please try again.", 'error');
      }
    } catch (error) {
      showToast("Error submitting form", 'error');
    }
  
    setIsSubmitting(false);
  };
  
  

  return (
    <section className="contact-main">
      <div className="container">
        <div className="section-main">
          <div className="careers-page overflow-hidden contact-pagemain">
          <h1 className="pagetitle"><strong>Contact Us</strong></h1>
            <div className="contact-page">
              <div className="contact-innermain w-full flex justify-between flex-wrap sm:flex-row">
                <form className="contact-form" onSubmit={handleSubmit}>
                  <div className="candidate">
                    <label htmlFor="name">
                      Name <small className="text-red-600">*</small>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      name="Name"
                      value={formData.Name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="candidate">
                    <label htmlFor="email">
                      Email <small className="text-red-600">*</small>
                    </label>
                    <input
                      type="email"
                      placeholder="Enter Email Address"
                      name="Email"
                      value={formData.Email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="candidate">
                    <label htmlFor="subject">
                      Subject
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Subject"
                      name="Subject"
                      value={formData.Subject}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="candidate">
                    <label htmlFor="subject">
                    Message
                    </label>
                    <textarea                                            
                      placeholder="Enter Message"
                      name="Message"
                      value={formData.Message}
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  {/* <div className="w-full">
                    <textarea
                      rows="4"
                      className="w-full block p-2.5 bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      placeholder="Enter Message"
                      name="Message"
                      value={formData.Message}
                      onChange={handleChange}
                    ></textarea>
                  </div> */}
                  <button
                    type="submit"
                    className="applybtn"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </form>

                <div className="contactinformation">
                  <div className="sociacl-media">
                    <a href="mailto:support@onecase.in" className="nowcontact" data-tooltip="support@onecase.in">
                      <IconEmail />
                    </a>
                    <Link to="https://in.linkedin.com/company/onecase.in" target="_blank" className="nowcontact" data-tooltip="LinkedIn">
                      <IconLinkdin />
                    </Link>
                  </div>                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
