import React from 'react'

export default function IconGuidance() {
  return (
    <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_750_2825)">
<path d="M10.9813 33.108H14.9282C15.0412 32.4421 15.273 31.8008 15.6119 31.2159H12.9512C12.4283 31.2159 12.0054 30.7918 12.0054 30.2706C12.0054 29.7494 12.4297 29.3253 12.9512 29.3253H17.3905C18.991 28.2627 21.0202 28.0832 22.7815 28.8475C24.5427 29.6119 25.7985 31.2159 26.1157 33.1095H41.4195C41.841 30.2098 44.1208 27.9312 47.022 27.5099V10.332C44.1208 9.91069 41.841 7.63207 41.4195 4.73241H10.9813C10.5598 7.63207 8.28006 9.91069 5.37891 10.332V27.5099C8.28006 27.9312 10.5598 30.2098 10.9813 33.1095V33.108ZM38.5025 31.2159H28.0913C27.5685 31.2159 27.1455 30.7918 27.1455 30.2706C27.1455 29.7494 27.5699 29.3253 28.0913 29.3253H38.5025C39.0253 29.3253 39.4483 29.7494 39.4483 30.2706C39.4483 30.7918 39.0239 31.2159 38.5025 31.2159ZM39.4497 26.4864C39.4497 27.009 39.0253 27.4317 38.5039 27.4317H23.3608C22.838 27.4317 22.415 27.0076 22.415 26.4864C22.415 25.9653 22.8394 25.5411 23.3608 25.5411H38.5039C39.0268 25.5411 39.4497 25.9653 39.4497 26.4864ZM26.1997 6.62161C30.9041 6.62161 34.7178 10.4333 34.7178 15.1353C34.7178 19.8373 30.9041 23.649 26.1997 23.649C21.4953 23.649 17.6817 19.8373 17.6817 15.1353C17.6874 10.4362 21.4967 6.6274 26.1997 6.62161ZM12.9497 25.5396H17.6817C18.2045 25.5396 18.6275 25.9638 18.6275 26.485C18.6275 27.0061 18.2031 27.4303 17.6817 27.4303H12.9497C12.4268 27.4303 12.0039 27.0061 12.0039 26.485C12.0039 25.9638 12.4283 25.5396 12.9497 25.5396Z" fill="#"/>
<path d="M6.32471 4.72951C5.80184 4.72951 5.37891 5.15367 5.37891 5.67483V8.41815C7.23576 8.03597 8.68706 6.58541 9.06944 4.72951H6.32471Z" fill="#"/>
<path d="M5.37891 32.1612C5.37891 32.6839 5.80329 33.1066 6.32471 33.1066H9.06944C8.68706 31.2507 7.23576 29.8001 5.37891 29.4179V32.1612Z" fill="#"/>
<path d="M26.1997 21.7569C29.8584 21.7569 32.8247 18.7921 32.8247 15.1353C32.8247 11.4785 29.8584 8.51369 26.1997 8.51369C22.541 8.51369 19.5747 11.4785 19.5747 15.1353C19.5791 18.7906 22.5425 21.7526 26.1997 21.7569ZM23.8098 15.0253H24.4341C25.6609 15.0253 26.3199 14.5143 26.3981 13.7311H23.8084V13.1636H26.3634C26.2041 12.5049 25.603 12.1763 24.6152 12.1763H23.8084V11.438H28.5896V12.0055H26.7153C27.0441 12.266 27.2831 12.641 27.3512 13.1636H28.5896V13.7311H27.3628C27.2498 14.8892 26.3301 15.6261 24.8759 15.6724C25.7608 16.5916 26.7269 17.7498 27.6814 18.918H26.5908C25.7044 17.8511 24.8759 16.8638 23.8084 15.7288V15.0253H23.8098Z" fill="#"/>
<path d="M14.9614 43.9206L16.7763 44.4591L18.4999 39.3518C17.8858 39.1173 17.318 38.7771 16.8197 38.3485L14.9614 43.9206Z" fill="#"/>
<path d="M46.0748 33.108C46.5977 33.108 47.0206 32.6839 47.0206 32.1627V29.4194C45.1638 29.8016 43.7125 31.2521 43.3301 33.108H46.0748Z" fill="#"/>
<path d="M49.8608 0H2.53858C0.971406 0 -0.300293 1.27105 -0.300293 2.83742V38.7828C-0.300293 40.3507 0.971406 41.6203 2.53858 41.6203H13.7333L15.4265 36.5448C15.186 36.0555 15.018 35.5357 14.9268 34.9987H6.32326C4.75464 34.9987 3.48439 33.7276 3.48439 32.1612V5.67628C3.48439 4.10846 4.75609 2.83886 6.32326 2.83886H46.0733C47.6419 2.83886 48.9121 4.10991 48.9121 5.67628V32.1627C48.9121 33.7305 47.6404 35.0001 46.0733 35.0001H26.1128C26.0216 35.5372 25.8535 36.0583 25.6131 36.5462L27.3063 41.6217H31.5545C31.9745 41.6217 32.3815 41.7607 32.7117 42.0184L45.4953 51.8046C45.7807 52.0246 46.1674 52.0622 46.4918 51.903C46.8148 51.7438 47.0205 51.4137 47.0191 51.0532V43.5138C47.0191 42.4686 47.8664 41.6217 48.9121 41.6217H49.8579C51.4266 41.6217 52.6968 40.3507 52.6968 38.7843V2.83742C52.6968 1.2696 51.4251 0 49.8579 0H49.8608Z" fill="#"/>
<path d="M22.541 39.3518L24.2559 44.4302L26.0708 43.8945L24.2212 38.3485C23.7229 38.7771 23.1551 39.1158 22.541 39.3518Z" fill="#"/>
<path d="M20.8902 37.8213C22.971 37.6155 24.491 35.7627 24.2852 33.6829C24.0793 31.6031 22.2255 30.0839 20.1447 30.2897C18.0638 30.4954 16.5438 32.3482 16.7497 34.428C16.9556 36.5078 18.8093 38.027 20.8902 37.8213Z" fill="#"/>
<path d="M20.5203 39.7296C20.4667 39.7296 20.4262 39.7224 20.3726 39.7224L19.731 41.6217H21.3097L20.6681 39.7224C20.6188 39.7224 20.5739 39.7296 20.5203 39.7296Z" fill="#"/>
<path d="M47.0206 5.67628C47.0206 5.15367 46.5962 4.73096 46.0748 4.73096H43.3301C43.7125 6.58686 45.1638 8.03742 47.0206 8.4196V5.67628Z" fill="#"/>
</g>
<defs>
<clipPath id="clip0_750_2825">
<rect width="53" height="52" fill="white" transform="translate(-0.300293)"/>
</clipPath>
</defs>
</svg>

  )
}
