import React, { useEffect, useId, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useOutsideClick } from "./useOutsideClick";

export function ExpandableCardDemo() {
  const [active, setActive] = useState(null);
  const id = useId();
  const ref = useRef(null);

  useEffect(() => {
    function onKeyDown(event) {
      if (event.key === "Escape") {
        setActive(false);
      }
    }

    if (active && typeof active === "object") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [active]);

  useOutsideClick(ref, () => setActive(null));

  return (<>
    <AnimatePresence>
      {active && typeof active === "object" && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 h-full w-full z-10 blureffectshow" />
      )}
    </AnimatePresence>
    <AnimatePresence>
      {active && typeof active === "object" ? (
        <div className="fixed inset-0 grid place-items-center z-[100]">
          <motion.div
            layoutId={`card-${active.title}-${id}`}
            ref={ref}
            className="openboxmodal w-full max-w-[700px] min-[500px]: flex flex-col overflow-hidden">
          <motion.button
            key={`button-${active.title}-${id}`}
            layout
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.05,
              },
            }}
            className="flex absolute top-2 right-2 lg:hidden items-center justify-center bg-white rounded-full h-6 w-6"
            onClick={() => setActive(null)}>
            <CloseIcon />
          </motion.button>
           <motion.div className="modalpictures" layoutId={`image-${active.title}-${id}`}>
                <img
                    priority                  
                    src={active.src}
                    alt={active.title}
                    className={`lightpic w-full ${
                        active.title === "Data Aggregation" || active.title === "Holistic View"
                          ? "px-3 py-3"
                          : "px-5 py-5"
                      }`}
                />
                <img
                    priority
                    src={active.src1}
                    alt={active.title}
                    className={`blackpic w-full ${
                        active.title === "Data Aggregation" || active.title === "Holistic View"
                          ? "px-3 py-3"
                          : ""
                      }`}
                />
                </motion.div>


            <div>
              <div className="flex justify-between flex-col pt-4 pb-6 px-4 md:px-8 lg:px-10">
                  <motion.h3 className="titlethreefi" layoutId={`title-${active.title}-${id}`}>
                    {active.title}
                  </motion.h3>
                  {/* <motion.p
                  className="detailsdec"
                    layoutId={`description-${active.description}-${id}`}>
                    {active.description}
                  </motion.p> */}
              </div>
              {/* <div className="pt-4 relative px-4">
                <motion.div
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="text-neutral-600 text-xs md:text-sm h-40 md:h-fit pb-10 flex flex-col items-start gap-4 overflow-auto dark:text-neutral-400 [mask:linear-gradient(to_bottom,white,white,transparent)] [scrollbar-width:none] [-ms-overflow-style:none] [-webkit-overflow-scrolling:touch]">
                  {typeof active.content === "function"
                    ? active.content()
                    : active.content}
                </motion.div>
              </div> */}
            </div>
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>
    <div className="max-w-full mx-auto w-full grid grid-cols-1 md:grid-cols-3 gap-4">
      {cards.map((card, index) => (
        <motion.div
          layoutId={`card-${card.title}-${id}`}
          key={card.title}
          onClick={() => setActive(card)}
          className="p-4 flex flex-col cardsofgrid rounded-xl cursor-pointer">
          <div className={`${index !== 0 && index !== 2 ? "flex gap-4 flex-col w-full twocolum " : "flex gap-4 flex-col w-full"}`}>
            {/* <motion.div layoutId={`image-${card.title}-${id}`} className={`${index !== 0 && index !== 2 ? "imgesboxnew py-3 px-4" : "imgesboxnew py-3 px-4"}`}> */}
            <motion.div layoutId={`image-${card.title}-${id}`} className={`${index !== 0 && index !== 2 ? "imgesboxnew py-3 px-3" : "imgesboxnew py-3 px-3"}`}> 
              <img
                src={card.src}
                alt={card.title}
                className={`lightpic w-full rounded-lg ${
                    index !== 0 && index !== 2 ? "" : ""
                  }`} />
                <img
                src={card.src1}
                alt={card.title}
                className={`blackpic w-full rounded-lg ${
                    index !== 0 && index !== 2 ? "" : ""
                  }`} />
            </motion.div>
            <div className="flex justify-center items-center flex-col">
              <motion.h3
                layoutId={`title-${card.title}-${id}`}
                className="text-center titlethreefi">
                {card.title}
              </motion.h3>
              {/* <motion.p
                layoutId={`description-${card.description}-${id}`}
                className="text-center detailsdec">
                {card.description}
              </motion.p> */}
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  </>);
}

export const CloseIcon = () => {
  return (
    (<motion.svg
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
        transition: {
          duration: 0.05,
        },
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="h-4 w-4 text-black">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M18 6l-12 12" />
      <path d="M6 6l12 12" />
    </motion.svg>)
  );
};

const cards = [
  {
    //description:"popular AI platform for developers.",
    title: "Data Aggregation",
    src: "../images/aggrigation.svg",
    src1:"../images/aggrigationbk.svg",        
  },
  {
    //description:"American heavy metal band, is renowned for their powerful sound and intense performances",
    title: "Portfolio Syncing",
    src: "../images/dimensionview.svg",
    src1: "../images/dimensionviewdark.svg",
  },

  {
    //description: "With over 100,000 mothly active bot users, Gippity AI is the most popular AI platform for developers.",
    title: "Holistic View",
    src: "../images/regular.svg",
    src1: "../images/regular.svg",
    // content: () => {
    //   return (
    //     (<p>Metallica, an iconic American heavy metal band, is renowned for their
    //                 powerful sound and intense performances that resonate deeply with
    //                 their audience. Formed in Los Angeles, California, they have become a
    //                 cultural icon in the heavy metal music industry. <br /> <br />Their
    //                 songs often reflect themes of aggression, social issues, and personal
    //                 struggles, capturing the essence of the heavy metal genre. With a
    //                 career spanning over four decades, Metallica has released numerous hit
    //                 albums and singles that have garnered them a massive fan following
    //                 both in the United States and abroad.
    //               </p>)
    //   );
    // },
  },

];
