import React, { useEffect, useState } from "react";
import { MultipleOutputDemo } from "../../component/AnimatedBeam";
import AboutInformaton from "../../component/AnimatedBeam/AboutInformaton";
import { BorderBeam } from '../../component/BorderBeam/BorderBeam';

export function AboutUs() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const handleScroll = (event) => {
    if (windowWidth >= 767) {
      // Scroll down, move to next slide
      if (event.deltaY > 0) {
        if (currentSlide < 1) setCurrentSlide(currentSlide + 1);
      } else {
        // Scroll up, move to previous slide
        if (currentSlide > 0) setCurrentSlide(currentSlide - 1);
      }
    }
  };

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateWindowWidth);
    // Only add wheel event listener if window width >= 767
    if (windowWidth >= 767) {
      window.addEventListener("resize", handleScroll, { passive: false });
    } else {
      window.removeEventListener("resize", handleScroll);
    }
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
      window.removeEventListener("resize", handleScroll);
    };
  }, [windowWidth, currentSlide]);

  return (
    <section className="about-main">
      <div className="container">
        <div className="section-main">        
          <div className="careers-page overflow-hidden">
          <div className="slides-container">
        <div className={`slide ${currentSlide === 0 ? "active" : ""} ${ currentSlide === 1 ? "previous" : ""}`}>   
        <AboutInformaton /> 
        </div>
       {/* <div className={`slide ${currentSlide === 1 ? "active" : ""} ${ currentSlide === 0 ? "previous" : ""}`}> 
       <MultipleOutputDemo />
      </div> */}
      </div>
       <BorderBeam size={250} duration={12} delay={9} />
       </div>
       
       {/* <div className="pagination">
        <button
          type="button"
          onClick={() => goToSlide(0)}
          className={`pagination-button ${currentSlide === 0 ? "active" : ""}`}
        ></button>
        <button
          type="button"
          onClick={() => goToSlide(1)}
          className={`pagination-button ${currentSlide === 1 ? "active" : ""}`}
        ></button>
              </div> */}
          </div>
        </div>
      </section>
  );
}

