import React from "react";

export default function OIS() {
  return (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"      
    >
      <g>
        <path d="m7.8988647 10h-2.3988647c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h1.2321777l-1.1791381-1.0106812c-.0183106.0020142-.0342408.0106812-.0530396.0106812h-2c-.2763672 0-.5-.2236328-.5-.5s.2236328-.5.5-.5h.8988647l-.72406-.6206055c-.2099609-.1796875-.234375-.4951172-.0541992-.7045898.1801758-.2104492.4960937-.2338867.7045898-.0541992l2.6748047 2.2926635v-1.4524536c0-.2763672.2236328-.5.5-.5s.5.2236328.5.5v2.3096313l1 .8571167v-1.1275634c0-.2763672.2236328-.5.5-.5s.5.2236328.5.5v1.9847412l.9411621.8067017c.0291138-.0938111.0588379-.1880493.0588379-.2914429 0-3.8598633-3.1401367-7-7-7h-1c-.5522461 0-1 .4477539-1 1 0 3.8598633 3.1401367 7 7 7h1c.0666504 0 .124939-.0255127.1879272-.0379639z"></path>
        <path d="m18.1011353 8h2.3988647c.2763672 0 .5-.2236328.5-.5s-.2236328-.5-.5-.5h-1.2321777l1.1791382-1.0106812c.0183105.0020142.0342407.0106812.0530395.0106812h2c.2763672 0 .5-.2236328.5-.5s-.2236328-.5-.5-.5h-.8988647l.7240601-.6206055c.2099609-.1796875.234375-.4951172.0541992-.7045898-.1801758-.2104492-.4960938-.2338867-.7045898-.0541992l-2.6748048 2.2926635v-1.4524536c0-.2763672-.2236328-.5-.5-.5s-.5.2236328-.5.5v2.3096313l-1 .8571167v-1.1275634c0-.2763672-.2236328-.5-.5-.5s-.5.2236328-.5.5v1.9847412l-.9411621.8067017c-.0291138-.0938111-.0588379-.1880493-.0588379-.2914429 0-3.8598633 3.1401367-7 7-7h1c.5522461 0 1 .4477539 1 1 0 3.8598633-3.1401367 7-7 7h-1c-.0666504 0-.124939-.0255127-.1879272-.0379639z"></path>
        <path d="m29 6h-2c-.5522461 0-1 .4477539-1 1 0 .4300537.2745361.7891846.6555176.9304199l-3.6555176 3.6555176-1.2929688-1.2929688c-.390625-.390625-1.0234375-.390625-1.4140625 0l-3.4942017 3.4942017c-.7121581-.8406982-1.6796874-1.4579468-2.798767-1.6860962v-9.1010742c0-.5522461-.4477539-1-1-1s-1 .4477539-1 1v9.1010742c-2.2792969.4646607-4 2.4844971-4 4.8989258 0 2.7568359 2.2431641 5 5 5s5-2.2431641 5-5c0-.4785156-.0890503-.932251-.2151489-1.3707886l3.2151489-3.2151489 1.2929688 1.2929688c.390625.390625 1.0234375.390625 1.4140625 0l4.3625488-4.3625488c.1412353.3809814.5003662.6555175.9304199.6555175.5522461 0 1-.4477539 1-1v-2c0-.5522461-.4477539-1-1-1zm-16 10.5c1.1025391 0 2 .8969727 2 2 0 .9293213-.6400146 1.7053833-1.5 1.9289551v.0710449c0 .2763672-.2236328.5-.5.5s-.5-.2236328-.5-.5v-.0710449c-.8599854-.2235718-1.5-.9996338-1.5-1.9289551 0-.2763672.2236328-.5.5-.5s.5.2236328.5.5c0 .5512695.4482422 1 1 1s1-.4487305 1-1-.4482422-1-1-1c-1.1025391 0-2-.8969727-2-2 0-.9293213.6400146-1.7053833 1.5-1.9289551v-.0710449c0-.2763672.2236328-.5.5-.5s.5.2236328.5.5v.0710449c.8599854.2235718 1.5.9996338 1.5 1.9289551 0 .2763672-.2236328.5-.5.5s-.5-.2236328-.5-.5c0-.5512695-.4482422-1-1-1s-1 .4487305-1 1 .4482422 1 1 1z"></path>
        <path d="m29 13h-2c-.5522842 0-1 .4477148-1 1v14h-1v-9c0-.5522842-.4477158-1-1-1h-2c-.5522842 0-1 .4477158-1 1v9h-1v-5c0-.5522842-.4477158-1-1-1h-2c-.5522842 0-1 .4477158-1 1v5h-1v-2c0-.5522842-.4477148-1-1-1h-2c-.5522852 0-1 .4477158-1 1v2h-8c-.5522461 0-1 .4476929-1 1 0 .5522461.4477539 1 1 1h26c.5522842 0 1-.4477158 1-1v-15c0-.5522852-.4477158-1-1-1z"></path>
      </g>
    </svg>
  );
}
