import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
     Copyright © {currentYear} All rights reserved. &nbsp;&nbsp; | &nbsp;&nbsp; <Link to="#">Privacy Policy</Link> &nbsp;&nbsp;| &nbsp;&nbsp; <Link to="#">Terms & Conditions</Link>
    </footer>
  );
}
