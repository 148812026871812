// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ToastDisplay.css */
.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  z-index: 1000;
  animation: fade-in 0.3s ease-out;
}

.toast.success {
  background-color: rgb(0, 184, 0);
}

.toast.error {
  background-color: rgb(250, 80, 80);
}

.toast.warning {
  background-color: rgb(158, 103, 0);
}

.toast.info {
  background-color: rgb(106, 106, 255);
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/services/ToastDisplay/style.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,wCAAwC;EACxC,eAAe;EACf,aAAa;EACb,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE;IACE,UAAU;IACV,2BAA2B;EAC7B;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;AACF","sourcesContent":["/* ToastDisplay.css */\r\n.toast {\r\n  position: fixed;\r\n  bottom: 20px;\r\n  right: 20px;\r\n  padding: 10px 20px;\r\n  background-color: #333;\r\n  color: white;\r\n  border-radius: 5px;\r\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\r\n  font-size: 16px;\r\n  z-index: 1000;\r\n  animation: fade-in 0.3s ease-out;\r\n}\r\n\r\n.toast.success {\r\n  background-color: rgb(0, 184, 0);\r\n}\r\n\r\n.toast.error {\r\n  background-color: rgb(250, 80, 80);\r\n}\r\n\r\n.toast.warning {\r\n  background-color: rgb(158, 103, 0);\r\n}\r\n\r\n.toast.info {\r\n  background-color: rgb(106, 106, 255);\r\n}\r\n\r\n@keyframes fade-in {\r\n  0% {\r\n    opacity: 0;\r\n    transform: translateY(20px);\r\n  }\r\n  100% {\r\n    opacity: 1;\r\n    transform: translateY(0);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
