import React from "react";
import IconEquity from "../Icons/abouticons/IconEquity";
import IconOthers from "../Icons/abouticons/IconOthers";
import IconMutualFunds from "../Icons/abouticons/IconMutualFunds";
import IconDebt from "../Icons/abouticons/IconDebt";
import IconLogeCenter from "../Icons/abouticons/IconLogeCenter";
import "./style.css"

export function MultipleOutputDemo() {
  return (         
    <div className="gridboxmain">
      <div className="boxone"><IconEquity/></div>
      <div className="boxtwo"><IconOthers/></div>
      <div className="boxthree"><IconDebt/></div>
      <div className="boxfour logoLogoBlack"> <IconLogeCenter/> </div>      
      <div className="boxfive"><IconMutualFunds/></div>      
    </div>
    
  );
}

