import React, { useEffect, useState } from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import LogoBlack from '../Icons/LogoBlack'
import LogoWhite from '../Icons/LogoWhite'
import IconMoon from '../Icons/IconMoon'
import IconSun from '../Icons/IconSun'

export default function Header({ toggleTheme, isDarkMode }) {
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 20 && !isScrolled) {
      // Add scrolled class if scroll is more than 1px and it's not already added
      setIsScrolled(true);
    } else if (window.scrollY === 0 && isScrolled) {
      // Remove scrolled class if scroll is at the top
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);
  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="container">
      <div className="innerhead">
        <div className='logo'>            
          <Link to="/">{isDarkMode ?  <LogoWhite /> :  <LogoBlack /> }</Link>
        </div>
        <label className="switch">
            <input 
              type="checkbox" 
              onChange={toggleTheme} 
              checked={isDarkMode} 
              hidden
            />
            <span className="slider round">
            {isDarkMode ? (
                <IconSun/>  
              ) : (
                 <IconMoon/>
              )}

            </span>
          </label>        
      </div>
      </div>
    </header>
  )
}
