import React from "react";
import { BorderBeam } from "../../component/BorderBeam/BorderBeam";
import { TypingAnimation } from "../../component/TypingAnimation/TypingAnimation";
import "./style.css";
import OneCaseTreeFour from "../../component/Icons/OneCaseTreeFour";

export default function HomeFour() {
  return (
    <section className="home-main">
      <div className="container">
        <div className="section-main overflow-hidden">
          <div className="home-inner">
            <div className="welcomecontentbox">
              <TypingAnimation
                text="Welcome to"
                strongText="OneCase"
                duration={100}
              />
              <p>
                We are excited to unveil an innovative Portfolio Management
                platform in India, crafted to enhance the investment journey.
                Utilizing advanced technology, our platform offers personalized
                insights and strategies, enabling clients to make confident,
                well-informed Investment decisions.
              </p>
            </div>

            {/* <MultipleOutputDemo/> */}
            <div className="logoboxhome financial">
             <OneCaseTreeFour />

              {/* <video autoPlay loop muted style={{width:"100%", height:"100%", objectFit:"cover",objectPosition:"-263px"}}>
              <source src="../images/tree.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}

            </div>

            <BorderBeam size={250} duration={12} delay={9} />
          </div>
        </div>
      </div>
    </section>
  );
}
