import React from 'react'

export default function IconRisk() {
  return (
   <svg width="53" height="49" viewBox="0 0 53 49" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPpath="url(#clip0_750_2814)">
<path d="M52.3453 44.7434L43.3993 29.0099C42.4469 27.3345 40.0793 27.3345 39.1268 29.0099L30.1808 44.7434C29.2284 46.4187 30.4129 48.5014 32.3177 48.5014H50.2097C52.1146 48.5014 53.2991 46.4187 52.3467 44.7434H52.3453ZM41.2718 45.9747C39.98 45.9747 39.5655 44.4914 40.3301 43.715C41.135 42.8977 42.573 43.4575 42.573 44.6467C42.573 45.4666 41.9841 45.9733 41.2718 45.9733V45.9747ZM42.5126 35.2605L42.1384 39.6124C42.0968 40.1313 42.0109 40.5291 41.8768 40.8069C41.6071 41.3695 40.809 41.3586 40.5662 40.8192C40.4468 40.5509 40.3583 40.1436 40.3073 39.5947L40.0283 35.3654C39.9773 34.5413 39.9505 33.9474 39.9505 33.5892C39.9505 32.6766 40.4388 32.0364 41.3201 32.0364C41.8191 32.0364 42.1518 32.2108 42.3181 32.5608C42.6454 33.2432 42.5891 34.3997 42.5113 35.2605H42.5126Z" fill="#"/>
<path d="M20.7774 33.5238C23.9057 36.7002 27.8508 38.5404 31.9261 39.0457L33.1065 36.9699C29.1681 36.7002 25.3048 35.0385 22.2946 31.982C15.6934 25.2791 15.6934 14.4097 22.2946 7.70681C28.8958 1.00398 39.6004 1.00398 46.2016 7.70681C52.7223 14.3279 52.8015 25.0121 46.4404 31.7327L47.5524 33.6887C55.1557 26.1509 55.2187 13.7804 47.7201 6.16493C40.2805 -1.38921 28.217 -1.38921 20.7774 6.16493C13.3379 13.7191 13.3379 25.9683 20.7774 33.5225V33.5238Z" fill="#"/>
<path d="M15.1336 35.7618C14.6762 35.2973 13.929 35.2973 13.4716 35.7618L6.37139 42.9713C5.91396 43.4358 5.91396 44.1944 6.37139 44.6589L9.80948 48.1499C10.2669 48.6144 11.0141 48.6144 11.4715 48.1499L18.5717 40.9404C19.0292 40.4759 19.0292 39.7173 18.5717 39.2528L15.1336 35.7618Z" fill="#"/>
<path d="M9.35228 25.1293V26.2707C9.35228 26.7202 9.71313 27.0866 10.1558 27.0866H13.1418C13.5845 27.0866 13.9453 26.7202 13.9453 26.2707V25.1293C14.1452 25.0803 14.3424 25.0244 14.5369 24.9631C14.2163 23.6841 14.0178 22.3792 13.9413 21.0675C10.1102 22.7143 5.76529 19.8771 5.76529 15.5633C5.76529 12.263 8.39986 9.58922 11.6488 9.58922C13.2371 9.58922 14.6778 10.228 15.7362 11.266C16.4994 9.56606 17.5015 7.95063 18.7423 6.47004L18.6176 6.34336C18.305 6.026 17.7939 6.026 17.48 6.34336L16.6845 7.15108C15.8462 6.63212 14.9259 6.23984 13.9453 5.99739V4.85596C13.9453 4.40647 13.5845 4.04007 13.1418 4.04007H10.1558C9.71313 4.04007 9.35228 4.40647 9.35228 4.85596V5.99739C8.37303 6.23848 7.45147 6.63212 6.61442 7.14972L5.81895 6.342C5.50639 6.02463 4.99531 6.02463 4.68141 6.342L2.57 8.48593C2.25744 8.80329 2.25744 9.32225 2.57 9.64098L3.36547 10.4487C2.85438 11.3 2.46805 12.2344 2.22927 13.2301H1.10381C0.661138 13.2301 0.300293 13.5965 0.300293 14.046V17.078C0.300293 17.5275 0.661138 17.8939 1.10381 17.8939H2.22793C2.46536 18.8882 2.85304 19.824 3.36412 20.6753L2.56866 21.483C2.2561 21.8003 2.2561 22.3193 2.56866 22.638L4.68007 24.782C4.99262 25.0993 5.50371 25.0993 5.81761 24.782L6.61307 23.9742C7.45147 24.4918 8.37169 24.8855 9.35094 25.1266L9.35228 25.1293Z" fill="#"/>
<path d="M18.246 32.6017L16.0474 34.8342L19.4855 38.3252L21.6841 36.0927C20.3976 35.0644 19.2574 33.9066 18.246 32.6017Z" fill="#"/>
<path d="M44.518 28.352L45.7655 30.5449C51.3794 24.3243 51.2211 14.6589 45.288 8.63439C39.1912 2.4437 29.3061 2.4437 23.2093 8.63439C17.1125 14.8251 17.1125 24.8623 23.2093 31.053C26.1524 34.0414 29.9782 35.586 33.8335 35.6896L38.0067 28.3506C38.2454 27.9297 38.5486 27.5715 38.8987 27.2814V23.9688H43.6259V27.2814C43.9774 27.5715 44.2792 27.9311 44.518 28.3506V28.352ZM29.5972 29.7577H24.87V18.1949H29.5972V29.7577ZM29.5972 9.60829H27.9057C28.2035 9.84801 28.4181 10.1913 28.4798 10.6694H29.5972V11.1897H28.4905C28.3886 12.2507 27.5583 12.9277 26.2463 12.9699C27.0458 13.8131 27.9164 14.8741 28.7776 15.9461H27.793C26.9935 14.9681 26.245 14.0623 25.2818 13.0217V12.376H25.8452C26.9519 12.376 27.5462 11.9075 27.6186 11.1897H25.2818V10.6694H27.5878C27.4442 10.0659 26.9009 9.76356 26.0089 9.76356H25.2805V9.0866H29.5959V9.60692L29.5972 9.60829ZM36.6116 29.7577H31.8844V20.6344H36.6116V29.7577ZM41.6366 21.6096C41.2798 21.6096 40.99 21.3154 40.99 20.9531C40.99 20.5908 41.2798 20.2966 41.6366 20.2966H42.0658L38.7123 16.8914L36.0482 18.4537C35.796 18.6348 35.4445 18.6117 35.2178 18.3815L30.5443 13.636C30.2921 13.3799 30.2921 12.9645 30.5443 12.7084C30.7965 12.4523 31.2056 12.4523 31.4578 12.7084L35.7826 17.0998L38.499 15.5075C38.7565 15.3577 39.0744 15.4081 39.2743 15.6124H39.2757L42.978 19.3704V18.9345C42.978 18.5722 43.2678 18.278 43.6246 18.278C43.9814 18.278 44.2712 18.5722 44.2712 18.9345V20.9545C44.2712 21.3168 43.9814 21.611 43.6246 21.611H41.6352L41.6366 21.6096Z" fill="#"/>
</g>
<defs>
<clipPath id="clip0_750_2814">
<rect width="53" height="48" fill="white" transform="translate(0.300293 0.5)"/>
</clipPath>
</defs>
</svg>

  )
}
