import React, { useEffect, useRef, useState } from "react";
import FileUpload from "../../component/Icons/FileUpload";
import IconChevronDown from "../Icons/IconChevronDown";
import { Link } from "react-router-dom";
import { BorderBeam } from "../BorderBeam/BorderBeam";
import axios from "axios";
import { useToast } from "../../pages/services/ToastProvider";
import IconClose from "../Icons/IconClose";

export default function CareersForm({jobTitle, backhandale}) {
  const [currentSlide, setCurrentSlide] = useState(0);
   const [formData, setFormData] = useState({
     nameofcandidate: "",
     jobpostname:jobTitle,
    totalexperience: "",
    currentlocation: "",
    educationalqualification: "",
    readyforrelocatio: "",
    currentcompany: "",
    currentctc: "",
    currentdesignation: "",
    expectedctc: "",
    reasonforchange: "",
    noticeperiod: "",
  });
const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const inputRef = useRef(null);
  const backurl = process.env.REACT_APP_API_KEY;
 const [file, setFile] = useState(null);
const [dragActive, setDragActive] = useState(false);
const [uploadMessage, setUploadMessage] = useState("");
const {showToast} = useToast()
  const handleButtonClick = () => {
    inputRef.current.click();
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(selectedFile.type)) {
      setFile(selectedFile);
      showToast("File selected. Click 'Upload' to save.", "info");
    } else {
      showToast("Invalid file format. Please upload a PDF or DOCX file.", "warning");
    }
  };
  
  const handleDragOver = (event) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

 const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
      showToast("File selected. Click 'Upload' to save.", 'info');
    }
    setDragActive(false);
  };

  const handleUpload = (e) => {
  e.preventDefault();
    if (!file) {
      showToast("Please select a file to upload.", 'info');
      return;
    }
    // Save file in state
    showToast("File saved locally.", 'success');
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // List of required fields
    const requiredFields = [
      "nameofcandidate",
      "totalexperience",
      "currentlocation",
      "educationalqualification",
      "readyforrelocatio",
      "currentcompany",
      "currentctc",
      "currentdesignation",
      "expectedctc",
      "reasonforchange",
      "noticeperiod",
    ];
  
    // Check for missing fields
    const missingFields = requiredFields.filter(field => !formData[field]);
  
    // If there are missing fields, show a warning toast
    if (missingFields.length > 0) {
      showToast("All fields are required to be filled.", "warning");
      return; // Stop submission
    }
  
    // Check if file is uploaded
    if (!file) {
      showToast("Please upload your CV.", 'warning');
      return; // Stop submission
    }
  
    // If everything is valid, prepare the form data and submit
    const formDataToSubmit = new FormData();
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        formDataToSubmit.append(key, formData[key]);
      }
    }
    
    // Append file directly to the FormData without using 'cvpath' key
    formDataToSubmit.append("file", file); // Changed 'cvpath' to 'file'
  
    try {
      // Send form data with file to backend
      const response = await axios.post(`${backurl}/careers-form`, formDataToSubmit, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log("response", response);
      // If the submission is successful, show success toast
      if (response.data.success) {
        showToast("Form submitted successfully!", 'success');
        
        // Clear form and file after successful submission
        setFormData({
          nameofcandidate: "",
          jobpostname: jobTitle,
          totalexperience: "",
          currentlocation: "",
          educationalqualification: "",
          readyforrelocatio: "",
          currentcompany: "",
          currentctc: "",
          currentdesignation: "",
          expectedctc: "",
          reasonforchange: "",
          noticeperiod: "",
        });
        setFile(null);
      } else {
        showToast("Something went wrong. Please try again.", "error");
      }
    } catch (error) {
      showToast("Error submitting the form.", 'error');
    }
  };
  

  
 const handleCancel = () => {
    // Reset form data and file state
    setFormData({
      nameofcandidate: "",
      totalexperience: "",
      currentlocation: "",
      educationalqualification: "",
      readyforrelocatio: "",
      currentcompany: "",
      currentctc: "",
      currentdesignation: "",
      expectedctc: "",
      reasonforchange: "",
      noticeperiod: "",
    });
    setFile(null);
    showToast("Erase All Fields", "info"); // Clear the upload message
  };

  // Slide navigation functions
  const goToSlide = (index) => {
    setCurrentSlide(index);
  };
  const handleScroll = (event) => {
    if (windowWidth >= 767) {
      // Scroll down, move to next slide
      if (event.deltaY > 0) {
        if (currentSlide < 1) setCurrentSlide(currentSlide + 1);
      } else {
        // Scroll up, move to previous slide
        if (currentSlide > 0) setCurrentSlide(currentSlide - 1);
      }
    }
  };

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateWindowWidth);

    // Only add wheel event listener if window width >= 767
    if (windowWidth >= 767) {
      window.addEventListener("wheel", handleScroll, { passive: true });
    } else {
      window.removeEventListener("wheel", handleScroll);
    }

    return () => {
      window.removeEventListener("resize", updateWindowWidth);
      window.removeEventListener("wheel", handleScroll);
    };
  }, [windowWidth, currentSlide]);

  return (
    <form onSubmit={handleSubmit} className="form-main section-main">      
      <div className="careers-page">
      <Link to="#" className="back-button formbackbtn" onClick={backhandale}>
        <IconClose/>
      </Link> 
      <div className="slides-container">
        <div className={`slide ${currentSlide === 0 ? "active" : ""} ${currentSlide === 1 ? "previous" : ""}`}>         
          <div className="forminner">               
          <div className="pagetitlesubtitle">
            <h1 className='pagetitle'><Link onClick={backhandale} to="#"><strong>Careers</strong></Link></h1>
            <IconChevronDown /> <h2><Link to='#'>{jobTitle}</Link> </h2> <IconChevronDown /> 
          <p>Apply Here</p>
          </div>       
            <div className="candidate-main firstfield">
              <div className="candidate">
                <label htmlFor="nameofcandidate">Name of Candidate:</label>
                <input type="text" value={formData.nameofcandidate}
                    onChange={handleFormChange} name="nameofcandidate" placeholder="Write Here" required />
              </div>
            </div>

            <div className="candidate-main">
              <div className="candidate">
                <label htmlFor="totalexperience">
                  Total Experience/
                  <br />
                  Relevant Experience
                </label>
                <input
                  type="text"
                  placeholder="Write Here"
                    name="totalexperience"
                    value={formData.totalexperience}
                    onChange={handleFormChange}
                />
              </div>
              <div className="candidate">
                <label htmlFor="currentlocation">Current Location</label>
                <input
                  type="text"
                  placeholder="Write Here"
                    name="currentlocation"
                    value={formData.currentlocation}
                    onChange={handleFormChange}
                />
              </div>
            </div>

            <div className="candidate-main">
              <div className="candidate">
                <label htmlFor="educationalqualification">Educational Qualification</label>
                <input
                  type="text"
                  placeholder="Write Here"
                  name="educationalqualification"
                  value={formData.educationalqualification}
                  onChange={handleFormChange}
                />
              </div>
              <div className="candidate">
                <label htmlFor="readyforrelocatio">
                  Ready for Relocation? If applicable.
                </label>
                  <input type="text" name="readyforrelocatio" placeholder="Write Here"
                 value={formData.readyforrelocatio}
                  onChange={handleFormChange}
                  />
              </div>
            </div>
            <div className="candidate-main">
              <div className="candidate">
                <label htmlFor="currentcompany">Current Company</label>
                <input
                  type="text"
                  placeholder="Write Here"
                    name="currentcompany"
                    value={formData.currentcompany}
                  onChange={handleFormChange}
                />
              </div>
              <div className="candidate">
                <label htmlFor="currentctc">Current CTC</label>
                  <input type="text" name="currentctc" placeholder="Write Here"
                  value={formData.currentctc}
                  onChange={handleFormChange}
                  />
              </div>
            </div>
            <div className="candidate-main">
              <div className="candidate">
                <label htmlFor="currentdesignation">Current Designation</label>
                <input
                  type="text"
                  name="currentdesignation"
                  placeholder="Write Here"
                  value={formData.currentdesignation}
                  onChange={handleFormChange}
                />
              </div>
              <div className="candidate">
                <label htmlFor="expectedctc">Expected CTC</label>
                <input
                  type="text"
                  name="expectedctc"
                  placeholder="Write Here"
                  value={formData.expectedctc}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="candidate-main">
              <div className="candidate">
                <label htmlFor="reasonforchange">Reason for change</label>
                <input
                  type="text"
                  name="reasonforchange"
                  placeholder="Write Here"
                  value={formData.reasonforchange}
                  onChange={handleFormChange}
                />
              </div>
              <div className="candidate">
                <label htmlFor="noticeperiod">Notice Period</label>
                <input
                  type="text"
                  name="noticeperiod"
                  placeholder="Write Here"
                  value={formData.noticeperiod}
                  onChange={handleFormChange}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Slide 2 */}
        <div className={`slide ${currentSlide === 1 ? "active" : ""} ${currentSlide === 0 ? "previous" : ""}`}>
          <div className="forminner">
            <div className="uploadtitle">
              <h5>Upload Your Resume Here</h5>
              <p>Please Upload only: Docx. or PDF Files</p>
            </div>

            <div
              className={`drag-area ${dragActive ? "active" : ""}`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <div className="icondropbox">
                <FileUpload />
              </div>
              <p>Drag and Drop here</p>
              <span>Or</span>
              <button onClick={handleButtonClick}>Browse File</button>
              <input
                type="file"
                ref={inputRef}
                onChange={handleFileChange}
                name="cvpath"
                hidden
                accept=".pdf, .docx"
              />
            </div>
            <button className="uploadbtn btntwo" onClick={handleUpload}>
              Upload
            </button>
            {uploadMessage && (
              <p style={{ textAlign: "center", fontSize: 10, marginTop: 5 }}>
                {uploadMessage}
              </p>
            )}
            <div className="submite-main">
              <button className="cancenbtn btntwo" onClick={handleCancel}>Cancel</button>
              <button className="submitbtn btntwo">Submit</button>
            </div>
          </div>
        </div>
      </div>

      <div className="pagination">
        <button
          type="button"
          onClick={() => goToSlide(0)}
          className={`pagination-button ${currentSlide === 0 ? "active" : ""}`}
        ></button>
        <button
          type="button"
          onClick={() => goToSlide(1)}
          className={`pagination-button ${currentSlide === 1 ? "active" : ""}`}
        ></button>
        </div>
        <BorderBeam size={250} duration={12} delay={9} /> 
        </div>
    </form>
  );
}
