import React, { useState } from "react";
import { JobData } from "./JobData";
import IconClose from "../Icons/IconClose";
import { Link } from "react-router-dom";
import IconBag from "../Icons/IconBag";
import IconResponse from "../Icons/IconResponse";
import { BorderBeam } from '../../component/BorderBeam/BorderBeam';
import { AnimatedTooltip } from "../Tooltip/AnimatedTooltip ";

const people = [
  {
    id: 1,
    name: "Job description",    
    image:"../images/info.svg",    
  }
]
export default function JobOpening({job, onJobTitleClick, onApplyClick}) {
  const [selectedJob, setSelectedJob] = useState(null);
  const handleBoxClick = (job) => {
    setSelectedJob(job); // Set the selected job to show its details
  };
  const handleBackClick = () => {
    setSelectedJob(null);
  };
  const handleTitleUpdate = (title) => {
    onJobTitleClick(title);
  };

  return (
    <div className="section-main overflow-hidden">
      <div className="careers-page">
      <h1 className='pagetitle'><strong>Careers</strong></h1>      
      {selectedJob ? (
        // If a job is selected, show its details
        <div className="job-details">
          <Link to="#" className="back-button" onClick={handleBackClick}>
                <IconClose />
              </Link>
          <div className="decriptiontitle">          
            <div className="businessanalystLeft">
              <h3>
                {selectedJob.title}
              </h3>
              <span>{selectedJob.location}</span>
            </div>
            <div className="appynow">
              <Link to="#" className="applybtn" onClick={onApplyClick}>
                Apply Now
              </Link>              
            </div>
          </div>
          <div className="aboutthis-job">
            <h4>About This Job</h4>
            <p>{selectedJob.describe}</p>
          </div>
          
          <div className="responsibilities">
          {selectedJob.requirements &&
            <div className="jobpointe">
           <strong>{selectedJob.requirements}</strong> 
              <ul>
            {selectedJob.point1 && <li><IconBag/> <span>{selectedJob.point1}</span></li>}
            {selectedJob.point2 && <li><IconBag/> <span>{selectedJob.point2}</span></li>}
            {selectedJob.point3 && <li><IconBag/> <span>{selectedJob.point3}</span></li>}
            {selectedJob.point4 && <li><IconBag/> <span>{selectedJob.point4}</span></li>}
            {selectedJob.point5 && <li><IconBag/> <span>{selectedJob.point5}</span></li>}
            {selectedJob.point6 && <li><IconBag/> <span>{selectedJob.point6}</span></li>}
            {selectedJob.point7 && <li><IconBag/> <span>{selectedJob.point7}</span></li>}
              </ul>
            </div>
          }
          {selectedJob.responsibilities &&
            <div className="jobpointe">
            <strong>{selectedJob.responsibilities}</strong> 
              <ul>
              {selectedJob.key1 && <li><IconResponse/>  <span>{selectedJob.key1}</span></li>}
                {selectedJob.key2 && <li><IconResponse/> <span>{selectedJob.key2}</span></li>}
                {selectedJob.key3 && <li><IconResponse/> <span>{selectedJob.key3}</span></li>}
                {selectedJob.key4 && <li><IconResponse/> <span>{selectedJob.key4}</span></li>}
                {selectedJob.key5 && <li><IconResponse/> <span>{selectedJob.key5}</span></li>}
                {selectedJob.key6 && <li><IconResponse /> <span>{selectedJob.key6}</span></li>}
                {selectedJob.key7 && <li><IconResponse/> <span>{selectedJob.key7}</span></li>}
              </ul>
            </div>
            }
          </div>
        </div>    
      ) : (
        // If no job is selected, show all job titles
        <div className="job-titles">
          {JobData.map((job) => (
            <div className="job-title" key={job.id}>
              <div className="flex items-center gap-2">
              <p>{job.title}</p>
              <Link
                    to="#"
                    className="back-button"
                    onClick={() => {
                      handleBoxClick(job);
                      onJobTitleClick(job.title);
                    }}
                  >
                  {/* <IconChevronDown /> */}
                  <AnimatedTooltip items={people}/> 
                </Link>
                </div>

              <div className="appynow">
              <Link to="#" className="applybtn hoverbtn" style={{pointerEvents:"none"}}>
                 View
                </Link>

                <Link  style={{zIndex:9,position:"relative"}} to="#" className="applybtn hoverbtn" 
                   onClick={() => {
                    handleTitleUpdate(job.title); // Pass job title here
                    onApplyClick(); // Trigger the apply functionality
                  }}>
                  Apply Now
                </Link>
                
              </div>
            </div>
          ))}
        </div>
      )}
      </div>
      <BorderBeam size={250} duration={12} delay={9} /> 
    </div>
  );
}
