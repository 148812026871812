import React from "react";
import IconConsolidation from "../Icons/abouticons/IconConsolidation";
import IconRisk from "../Icons/abouticons/IconRisk";
import IconGuidance from "../Icons/abouticons/IconGuidance";
import { TypingAnimation } from "../TypingAnimation/TypingAnimation";

export default function AboutInformaton() {
  return (
    <div className="seconfslide w-full h-full">
      {/* <h1 class="pagetitle"><strong>About</strong></h1> */}
      <div className="aboutInformaton w-full h-full">
        <div className="about-infomain w-full flex justify-center md:flex-row sm:flex-col">
          <div className="about-left">
            <div className="revolutionary">
              <TypingAnimation
                text="About"
                strongText="OneCase"
                duration={100}
              />
              <p>
                OneCase is a revolutionary platform designed to bring all your
                financial data Equity, Mutual Funds, Debt, and other investments
                together in one place. OneCase simplifies financial management
                for 20 crore Indians, empowering them to take full control of
                their finances. Our mission is to provide a seamless,
                user-friendly experience that makes investment tracking and
                decision-making easier than ever. With OneCase, you can access a
                360° view of your portfolio anytime, anywhere. We are here to
                help you achieve financial clarity and take confident steps
                toward your goals.
              </p>
            </div>
            {/* <div className='about-left-boxmain flex'>
           <div className='boxsolidation'>
              <IconConsolidation />
              <p>Consolidation</p>
            </div> 
          <div className='boxsolidation'>
              <IconRisk />
              <p>Risk Assesment</p>
            </div>
            <div className='boxsolidation'>
              <IconGuidance />
              <p>Strategic Guidance</p>
            </div>            
          </div> */}
          </div>
          <div className="about-right">
            <img
              src="../images/All-Assetblack.svg"
              alt="About"
              className="w-full blackpic"
            />
            <img
              src="../images/All-Asset.svg"
              alt="About"
              className="w-full lightpic"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
