import React from 'react'

export default function FileUpload() {
  return (
<svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="76" height="76" rx="38" fill="#F7FAFF"/>
<rect x="8" y="8" width="60" height="60" rx="30" fill="#F0F6FF"/>
<rect x="19.5" y="19.5" width="37" height="37" rx="18.5" stroke="#00356B" strokeWidth="3"/>
<path fillRule="evenodd" clipRule="evenodd" d="M33.368 34.6099C33.782 34.6099 34.118 34.9373 34.118 35.3406C34.118 35.744 33.782 36.0713 33.368 36.0713H32.435C30.816 36.0713 29.5 37.3534 29.5 38.9298V43.6792C29.5 45.2565 30.816 46.5386 32.435 46.5386H43.565C45.183 46.5386 46.5 45.2565 46.5 43.6792V38.921C46.5 37.3495 45.188 36.0713 43.576 36.0713H42.633C42.219 36.0713 41.883 35.744 41.883 35.3406C41.883 34.9373 42.219 34.6099 42.633 34.6099H43.576C46.015 34.6099 48 36.5438 48 38.921V43.6792C48 46.0622 46.01 48 43.565 48H32.435C29.99 48 28 46.0622 28 43.6792V38.9298C28 36.5477 29.99 34.6099 32.435 34.6099H33.368ZM38.5306 28.2068L41.4466 31.0594C41.7386 31.3458 41.7376 31.8076 41.4446 32.0921C41.1506 32.3766 40.6766 32.3766 40.3846 32.0901L38.749 30.4912L38.7496 41.1831H37.2496L37.249 30.4912L35.6156 32.0901C35.4696 32.2343 35.2766 32.3054 35.0846 32.3054C34.8936 32.3054 34.7016 32.2343 34.5556 32.0921C34.2626 31.8076 34.2606 31.3458 34.5536 31.0594L37.4686 28.2068C37.7496 27.9311 38.2496 27.9311 38.5306 28.2068Z" fill="#00356B"/>
</svg>


  )
}
