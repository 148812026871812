import React from "react";

export default function IconMoon() {
  return (
    <svg
      height="30"
      viewBox="0 0 512 512"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m497.139 291.079a7.955 7.955 0 0 0 -9.837 2.9 206.2 206.2 0 0 1 -173.135 90.951c-55.12-.455-106.666-22.053-145.144-60.814a203.685 203.685 0 0 1 5.723-292.736 8.012 8.012 0 0 0 -8.44-13.3 248.158 248.158 0 0 0 -113.071 90.186 246.145 246.145 0 0 0 30.28 314.794c46.813 46.069 109.046 71.44 175.232 71.44a250.283 250.283 0 0 0 157.1-55.5 247.629 247.629 0 0 0 85.963-138.863 7.909 7.909 0 0 0 -4.671-9.058zm-88.847 138.6a238.246 238.246 0 0 1 -149.545 52.821c-63.018 0-122.261-24.147-166.815-67.994a234.141 234.141 0 0 1 -28.811-299.437 236.089 236.089 0 0 1 88.738-77.364 215.841 215.841 0 0 0 8.648 294.862c40.721 41.021 95.257 63.878 153.56 64.36.617 0 1.226.008 1.842.008a218.123 218.123 0 0 0 168.911-79.57 236.207 236.207 0 0 1 -76.528 112.31z"
        fill="#"
      ></path>
    </svg>
  );
}
