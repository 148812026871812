import React from 'react'

export default function IconConsolidation() {
  return (
    <svg width="53" height="61" viewBox="0 0 53 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_750_2803)">
<path d="M21.6003 5.1478C11.1542 7.12031 2.84591 15.2708 0.504032 25.7269C0.488861 25.7941 0.523341 25.8655 0.589542 25.8935C0.655744 25.9215 0.730221 25.8935 0.76608 25.8305C3.06107 21.7945 9.67572 21.5845 12.4162 25.1991C13.1154 19.3572 14.7346 14.2152 16.951 10.4368C18.2957 8.14367 19.8639 6.33774 21.6003 5.1478Z" fill="#"/>
<path d="M18.9069 11.6113C17.1704 14.575 15.8216 18.4766 15.0479 22.941C15.1968 22.8374 15.3527 22.7422 15.5085 22.647C16.8298 21.8687 18.3855 21.4809 19.9344 21.4809C21.4832 21.4809 23.0389 21.8701 24.3602 22.647C25.185 23.1356 25.9201 23.774 26.5021 24.5594C27.0814 23.774 27.8151 23.1342 28.6413 22.647C29.9584 21.8729 31.5141 21.4837 33.0671 21.4837V21.4795C34.6159 21.4795 36.1717 21.8687 37.493 22.6456C37.6488 22.7408 37.806 22.836 37.9536 22.9396C37.1799 18.4752 35.831 14.575 34.0946 11.6099C31.9831 8.01067 29.3322 5.84357 26.5021 5.84357C23.672 5.84357 21.017 8.01207 18.9069 11.6113Z" fill="#"/>
<path d="M28.4604 2.44452C28.4328 1.37356 27.5611 0.5 26.5019 0.5C25.4427 0.5 24.5669 1.37356 24.5435 2.44452C25.1889 2.40112 25.8413 2.37732 26.5019 2.37732C27.1626 2.37732 27.8122 2.40112 28.4604 2.44452Z" fill="#"/>
<path d="M52.4971 25.7241C50.1552 15.2708 41.8469 7.11891 31.4009 5.1478C33.1373 6.33775 34.7082 8.14367 36.0488 10.4382C38.2652 14.2166 39.8843 19.3586 40.5836 25.2047C43.3254 21.5957 49.9401 21.7903 52.2351 25.8305C52.2709 25.8935 52.344 25.9173 52.4116 25.8907C52.4778 25.8669 52.5137 25.7955 52.4971 25.7241Z" fill="#"/>
<path d="M38.4888 34.0047L28.4606 31.1909V28.0914C28.0027 28.7354 27.2524 29.1274 26.447 29.1078C25.6774 29.0924 24.9767 28.7074 24.5395 28.0998V31.1909L14.5114 34.0047C13.8383 34.1909 13.397 34.7299 13.397 35.3613V48.7699C13.397 55.2222 19.2931 60.5 26.5021 60.5C33.7112 60.5 39.6032 55.2222 39.6032 48.7699V35.3613C39.6032 34.7313 39.166 34.1909 38.4888 34.0047ZM36.8944 48.7713C36.8944 54.3109 31.3942 57.7519 26.5035 57.7519C23.9313 57.7519 21.1826 56.8028 19.1689 55.1004C17.3429 53.5577 16.1071 51.4018 16.1071 48.7713V37.2904H16.1112C16.1112 36.7864 16.4395 36.3216 16.9443 36.1789L26.1863 33.5876C26.3821 33.5316 26.5932 33.5274 26.8042 33.5834L35.9717 36.1551C36.4958 36.2587 36.8944 36.7276 36.8944 37.2904V48.7713Z" fill="#"/>
<path d="M18.3853 38.1668V48.7699C18.3853 50.6472 19.2845 52.2026 20.6182 53.3295C22.2208 54.6818 24.4262 55.4364 26.5019 55.4364C30.3181 55.4364 34.6143 52.8857 34.6143 48.7699V38.1668L26.5019 35.8933L18.3853 38.1668ZM30.2947 40.9988H27.3211C27.8438 41.4188 28.2217 42.0236 28.3307 42.865H30.2947V43.7791H28.3486C28.1679 45.6452 26.7087 46.8338 24.4013 46.908C25.8067 48.3905 27.339 50.2566 28.8534 52.141H27.1225C25.7171 50.4218 24.4013 48.8301 22.7063 47.0004V45.8664H23.6979C25.644 45.8664 26.6894 45.0433 26.8163 43.7805H22.7063V42.8664H26.7611C26.5087 41.8052 25.553 41.2746 23.9848 41.2746H22.7049V40.0861H30.2933V41.0016L30.2947 40.9988Z" fill="#"/>
</g>
<defs>
<clipPath id="clip0_750_2803">
<rect width="52" height="60" fill="white" transform="translate(0.5 0.5)"/>
</clipPath>
</defs>
</svg>

  )
}
