import React from 'react';
import './style.css';
import { ExpandableCardDemo } from '../../component/bento-grid';
export default function Features() {
  
  return (
    
      <section className="features-main">
        <div className="container">
        <div className="section-main">
          <h1 className='pagetitle'><strong>Features</strong></h1>
          <div className='h-full w-full flex flex-col justify-center'>          
            <ExpandableCardDemo/>
            </div>        
        </div>
        </div>
      </section>
    
  );
}
