import React from "react";

export default function IconSun() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"      
      viewBox="0 0 512 512"
      width="30"
      height="30"
    >
      <path d="M256,96.745C168.187,96.745,96.745,168.187,96.745,256S168.187,415.255,256,415.255,415.255,343.813,415.255,256,343.813,96.745,256,96.745Zm0,298.51c-76.786,0-139.255-62.469-139.255-139.255S179.214,116.745,256,116.745,395.255,179.214,395.255,256,332.786,395.255,256,395.255Z"></path>
      <path d="M256,67.293a10,10,0,0,0,10-10V10a10,10,0,0,0-20,0V57.293A10,10,0,0,0,256,67.293Z"></path>
      <path d="M256,444.707a10,10,0,0,0-10,10V502a10,10,0,0,0,20,0V454.707A10,10,0,0,0,256,444.707Z"></path>
      <path d="M147.986,88.915a10,10,0,0,0,17.321-10L141.66,37.958a10,10,0,1,0-17.32,10Z"></path>
      <path d="M364.014,423.085a10,10,0,0,0-17.321,10l23.647,40.957a10,10,0,1,0,17.32-10Z"></path>
      <path d="M88.915,147.986,47.958,124.34a10,10,0,1,0-10,17.32l40.957,23.647a10,10,0,0,0,10-17.321Z"></path>
      <path d="M474.042,370.34l-40.957-23.647a10,10,0,0,0-10,17.321l40.957,23.646a10,10,0,1,0,10-17.32Z"></path>
      <path d="M67.293,256a10,10,0,0,0-10-10H10a10,10,0,0,0,0,20H57.293A10,10,0,0,0,67.293,256Z"></path>
      <path d="M502,246H454.707a10,10,0,0,0,0,20H502a10,10,0,0,0,0-20Z"></path>
      <path d="M78.915,346.693,37.958,370.34a10,10,0,1,0,10,17.32l40.957-23.646a10,10,0,0,0-10-17.321Z"></path>
      <path d="M428.1,166.648a9.953,9.953,0,0,0,4.99-1.341l40.957-23.647a10,10,0,1,0-10-17.32l-40.957,23.646a10,10,0,0,0,5.01,18.662Z"></path>
      <path d="M161.646,419.425a10,10,0,0,0-13.66,3.66L124.34,464.042a10,10,0,1,0,17.32,10l23.647-40.957A10,10,0,0,0,161.646,419.425Z"></path>
      <path d="M350.354,92.575a10,10,0,0,0,13.66-3.66L387.66,47.958a10,10,0,1,0-17.32-10L346.693,78.915A10,10,0,0,0,350.354,92.575Z"></path>
    </svg>
  );
}
