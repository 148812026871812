import React from 'react'

export default function IconDebt() {
  return (
    <>        
    <svg className='lightsvgicon' width="153" height="328" viewBox="0 0 153 328" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H141V74L152.5 84L141 95.5V328H25C11.1929 328 0 316.807 0 303V0Z" fill="#F1F5F9"/>
    <path d="M52.5 156.96C56.98 156.96 59.86 159.8 59.86 163.98C59.86 168.14 56.98 171 52.5 171H47.24V156.96H52.5ZM50.66 168.04H52.28C54.86 168.04 56.38 166.56 56.38 163.98C56.38 161.4 54.86 159.88 52.28 159.88H50.66V168.04ZM66.6913 162.42C65.5713 162.42 64.7113 163.1 64.5113 164.34H68.7913C68.7913 163.12 67.8313 162.42 66.6913 162.42ZM72.0313 167.3C71.4913 169.48 69.5113 171.16 66.7313 171.16C63.4513 171.16 61.0913 168.96 61.0913 165.42C61.0913 161.88 63.4113 159.68 66.7313 159.68C69.9913 159.68 72.2912 161.84 72.2912 165.24C72.2912 165.56 72.2713 165.9 72.2313 166.24H64.4913C64.6113 167.7 65.5113 168.38 66.6113 168.38C67.5713 168.38 68.1113 167.9 68.3913 167.3H72.0313ZM77.5155 161.42C78.1355 160.42 79.3555 159.68 80.9555 159.68C83.7155 159.68 85.8755 161.88 85.8755 165.4C85.8755 168.92 83.6955 171.16 80.9555 171.16C79.3155 171.16 78.1355 170.42 77.5155 169.44V171H74.0955V156.2H77.5155V161.42ZM82.3955 165.4C82.3955 163.62 81.2355 162.66 79.9355 162.66C78.6555 162.66 77.4955 163.64 77.4955 165.42C77.4955 167.2 78.6555 168.18 79.9355 168.18C81.2355 168.18 82.3955 167.18 82.3955 165.4ZM88.2097 167.12V162.68H86.8497V159.84H88.2097V157.12H91.6297V159.84H93.8697V162.68H91.6297V167.16C91.6297 167.82 91.9097 168.1 92.6697 168.1H93.8897V171H92.1497C89.8297 171 88.2097 170.02 88.2097 167.12Z" fill="#00356B"/>
    </svg>
    <svg  className='darksvg' width="153" height="328" viewBox="0 0 153 328" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H141V74L152.5 84L141 95.5V328H25C11.1929 328 0 316.807 0 303V0Z" fill="#1A1A1A"/>
    <path d="M52.5 156.96C56.98 156.96 59.86 159.8 59.86 163.98C59.86 168.14 56.98 171 52.5 171H47.24V156.96H52.5ZM50.66 168.04H52.28C54.86 168.04 56.38 166.56 56.38 163.98C56.38 161.4 54.86 159.88 52.28 159.88H50.66V168.04ZM66.6913 162.42C65.5713 162.42 64.7113 163.1 64.5113 164.34H68.7913C68.7913 163.12 67.8313 162.42 66.6913 162.42ZM72.0313 167.3C71.4913 169.48 69.5113 171.16 66.7313 171.16C63.4513 171.16 61.0913 168.96 61.0913 165.42C61.0913 161.88 63.4113 159.68 66.7313 159.68C69.9913 159.68 72.2912 161.84 72.2912 165.24C72.2912 165.56 72.2713 165.9 72.2313 166.24H64.4913C64.6113 167.7 65.5113 168.38 66.6113 168.38C67.5713 168.38 68.1113 167.9 68.3913 167.3H72.0313ZM77.5155 161.42C78.1355 160.42 79.3555 159.68 80.9555 159.68C83.7155 159.68 85.8755 161.88 85.8755 165.4C85.8755 168.92 83.6955 171.16 80.9555 171.16C79.3155 171.16 78.1355 170.42 77.5155 169.44V171H74.0955V156.2H77.5155V161.42ZM82.3955 165.4C82.3955 163.62 81.2355 162.66 79.9355 162.66C78.6555 162.66 77.4955 163.64 77.4955 165.42C77.4955 167.2 78.6555 168.18 79.9355 168.18C81.2355 168.18 82.3955 167.18 82.3955 165.4ZM88.2097 167.12V162.68H86.8497V159.84H88.2097V157.12H91.6297V159.84H93.8697V162.68H91.6297V167.16C91.6297 167.82 91.9097 168.1 92.6697 168.1H93.8897V171H92.1497C89.8297 171 88.2097 170.02 88.2097 167.12Z" fill="white"/>
    </svg>
    </>  
  )
}
